import { ProductCard } from '$shared/components';
import { memo, useEffect, useRef, ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import useCustomer from '~/features/commerce-api/hooks/useCustomer';
import { useWishlist } from '~/features/commerce-api/hooks/useWishlist';
import { M31RaptorProductCarouselModule as M31RaptorCarouselModuleType } from '~/lib';
import { useRaptorProducts } from '~/services/raptor/useRaptorProducts';
import { useProductHandler } from '~/services/search/useProductHandler';
import { CardCarousel } from '~/shared/components/CardCarousel/CardCarousel';
import { useEvents } from '~/shared/hooks/useEvents';
import { useFrame } from '~/shared/utils';
import { ModuleContainer } from '../ModuleContainer';
import { _transformProductDetailsToDataLayer } from '~/shared/hooks/useEvents/helpers';
import { TProductDetails } from '~/shared/components/ProductCard/ProductCard.definition';
import ProductCardLoader from '~/shared/components/ProductCard/ProductCardLoader';

export type M31Props = M31RaptorCarouselModuleType;

const Skeletons = () => {
    const skeletons = [...Array(5)].map(() => {
        const id = crypto.randomUUID();

        return <ProductCardLoader key={`skeleton-${id}`} uniqueKey={id} />;
    });

    return skeletons;
};

const M31RaptorProductCarouselBlockComponent = ({
    headline,
    subHeadline,
    callToAction,
    priority,
    raptorAlgorithm,
    raptorParameter,
    productId,
    productBrand,
    productCategory,
    merchandisingCampaignId,
    ...rest
}: M31Props) => {
    const { ref, inView } = useInView({ rootMargin: '1000px 0px', threshold: 0 });

    const { data: frame } = useFrame();
    const { productsImpressionsEvent } = useEvents(frame);
    const { isPlusMember } = useCustomer();
    const productHandler = useProductHandler();
    const { handleFavourite, isFavorite } = useWishlist();

    const {
        data: products,
        isSuccess,
        isError,
        isLoading,
    } = useRaptorProducts({
        config: raptorAlgorithm,
        param: raptorParameter,
        productId,
        productBrand,
        productCategory,
        merchandisingCampaignId,
        preventAutoFetch: !inView,
    });

    const impressionTracked = useRef(new Set<string>());

    useEffect(() => {
        if (
            isSuccess &&
            Array.isArray(products) &&
            products.length > 0 &&
            !impressionTracked.current.has(raptorAlgorithm)
        ) {
            productsImpressionsEvent(
                raptorAlgorithm,
                raptorAlgorithm,
                products.map((item: TProductDetails, index: number) =>
                    _transformProductDetailsToDataLayer(item, 1, index, isPlusMember),
                ),
            );
            impressionTracked.current.add(raptorAlgorithm);
        }
    }, [isSuccess, products, raptorAlgorithm, isPlusMember]);

    if (isError || (isSuccess && (!Array.isArray(products) || products.length === 0))) {
        return null;
    }

    const renderItems = (): ReactNode[] => {
        if (!isSuccess && isLoading) {
            return Skeletons();
        }

        if (!Array.isArray(products)) {
            return [];
        }

        return products.map((product: TProductDetails, index: number) => (
            <ProductCard
                key={`${product.id}-${index}`}
                {...product}
                priority={priority}
                position={index}
                listIdentifier={`Raptor - ${raptorAlgorithm}`}
                onBuyBtn={productHandler}
                onFavourite={handleFavourite}
                isFavourite={isFavorite(product.id)}
                raptorAlgorithm={raptorAlgorithm}
            />
        ));
    };

    const items = renderItems();

    return (
        <ModuleContainer {...rest}>
            <div ref={ref}>
                <CardCarousel
                    options={{
                        slidesPerView: 'auto' as const,
                        spaceBetween: 24,
                    }}
                    headline={headline}
                    subHeadline={subHeadline}
                    callToAction={!isLoading && isSuccess ? callToAction : undefined}
                    items={items}
                />
            </div>
        </ModuleContainer>
    );
};

export const M31RaptorProductCarouselBlock = memo(M31RaptorProductCarouselBlockComponent);
